import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

const HomeIntro = () => {
    return (
        <section className="about contact">
            <div className="container section-title aos-init aos-animate" data-aos="fade-up">  
                    <div className='row'>
                            <div className='row'>
                                <div className='col-12'>
                                    <h2> My Expertise at Your Service</h2>
                                    <p><small className='text-info'>Last updated :December 8, Sunday 2024</small></p>
                                </div>
                                <div className='col-12 info-wrap'>
                                    <h4 className='text-success'>Web Hosting</h4>
                                    <p  className='pb-4'>
                                        Web hosting is the foundation of your online presence. It involves renting space on a server to store your website files, making them accessible on the internet. This service ensures that your website is operational 24/7, allowing users to access it from anywhere in the world
                                    </p>
                                    
                                    <h4 className='text-success'>Website Maintenance</h4>
                                    <p>Maintaining a website is crucial for its performance and security.</p>
                                    <p>This service includes:</p>
                                    <p className='pb-4'>
                                        <ul>
                                            <li><strong>Regular Updates:</strong> Keeping software, plugins, and themes up-to-date to prevent vulnerabilities and improve functionality. </li>
                                            <li><strong>Performance Monitoring:</strong> Regular checks to ensure the website runs smoothly and efficiently. </li>
                                            <li><strong>Backup Solutions:</strong> Implementing regular backups to safeguard your data against loss or corruption</li>
                                        </ul>
                                    </p>

                                    <h4 className='text-success'>Website Creation</h4>
                                    <p className='pb-4'>
                                        Creating a new website involves several steps tailored to meet your specific needs:
                                        Custom Design: Developing a unique design that reflects your brand identity and engages your target audience.
                                        Content Management Systems (CMS): Utilizing platforms like WordPress or custom solutions to facilitate easy content updates and management58.
                                        SEO Optimization: Ensuring that the website is optimized for search engines to improve visibility and attract more visitors.
                                    </p>
                                    
                                    <h4 className='text-info'>Art work Offerings</h4>
                                    <p>
                                        <ul>
                                            <li>Custom acrylic paintings (commissions)</li>
                                            <li>Acrylic painting classes or workshops</li>
                                            <li>Selling original acrylic artworks or prints</li>
                                            <li>Restorations or touch-ups of existing artworks</li>
                                        </ul>
                                    </p>
                                </div>
                            </div>

                            <div className="col-md-2 about pt-5"> 
                                <div className="title">
                                    <h5>Events</h5>
                                    <h6>December 1, Sunday 2024 (11:00 am - 3:00 pm)</h6>
                                </div> 
                                <div className="news"> 
                                    <Zoom>
                                        <img 
                                            alt="Artlimerick" 
                                            src="assets/img/artlimerick.jpg" 
                                            width="100" 
                                            style={{ cursor: 'pointer' }} 
                                        />
                                    </Zoom>
                                </div> 
                            </div>                            
                            <div className="col-md-10 pt-5"> 
                                <h5>Don't forget to follow me on <i className="bi bi-badge-sd"></i> <a target="_blank" href='https://www.linkedin.com/in/sambhudayalb/'>Linkedin</a> for updates and more!</h5>
                                <p>
                                
                                I invite you to explore my portfolio, where you can find a diverse range of paintings that refresh your hearts and souls. <br></br><a href="/art-gallery"><i className="bi bi-easel"></i> Go to my art gallery</a>
                                <br></br>
                                 
                                </p>
                                <p className='pt-5'>
                                <small>Last updated 13th November Wednesday 2024</small> 
                                </p>
                            </div>
                    </div>




                    <hr className='mb-5'></hr>
                    <h2>Professional Experience</h2>
                    <div className="content mt-5">
                        <h2><span>Web Development</span></h2>
                        <p className='pb-3'><strong>Requirement Analysis & Implementation</strong><br />Extensive experience in requirement analysis, implementation, and developer testing of dynamic websites and web applications.</p>
                        
                        <div className='row'>
                            <div className='col-6'>
                                <p><strong>Proficient Technologies</strong><br />Skilled in PHP and well-versed in a spectrum of front-end technologies, including:</p>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> TypeScript</li>
                                    <li><i className="bi bi-chevron-right"></i> React</li>
                                    <li><i className="bi bi-chevron-right"></i> Angular</li>
                                    <li><i className="bi bi-chevron-right"></i> Vue</li>
                                    <li><i className="bi bi-chevron-right"></i> Node.js</li>
                                    <li><i className="bi bi-chevron-right"></i> Bootstrap</li>
                                </ul>
                            </div>
                            <div className='col-6'>
                            <p><strong>Domain Expertise</strong><br />Hands-on experience in creating websites for various domains, such as:</p>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> E-commerce</li>
                                    <li><i className="bi bi-chevron-right"></i> Geographic Information Systems (GIS)</li>
                                    <li><i className="bi bi-chevron-right"></i> Content Management Systems (CMS)</li>
                                    <li><i className="bi bi-chevron-right"></i> Enterprise Resource Planning (ERP)</li>
                                    <li><i className="bi bi-chevron-right"></i> Educational software</li>
                                    <li><i className="bi bi-chevron-right"></i> Online gaming</li>
                                    <li><i className="bi bi-chevron-right"></i> Digital humanities</li>
                                </ul>
                            </div>
                        </div>
                        <h2 className='pt-4'><span>Frameworks & Methodologies</span></h2>
                        <div className='row'>
                            <div className='col-6'>
                                <p><strong>Back-End Frameworks</strong><br />Demonstrated proficiency in PHP back-end frameworks such as:</p>
                                <ul>
                                    <li><i className="bi bi-chevron-right"></i> Laravel</li>
                                    <li><i className="bi bi-chevron-right"></i> CodeIgniter</li>
                                    <li><i className="bi bi-chevron-right"></i> WordPress</li>
                                    <li><i className="bi bi-chevron-right"></i> Joomla</li>
                                    <li><i className="bi bi-chevron-right"></i> OpenCart</li>
                                </ul>
                            </div>
                            <div className='col-6'>
                                <p className='pb-3'><strong>Development Process</strong><br />Proven track record in end-to-end website development, including testing, hosting support, and maintenance.</p>
                                <p className='pb-3'><strong>Agile Methodology</strong><br />Skilled in agile methodology with exposure to agile ceremonies, ensuring efficient team collaboration and delivery.</p>
                            </div>
                        </div>
                        <h2 className='pt-4'><span>Artistic Endeavors</span></h2>
                        <p className='pb-3'><strong>Creative Skills</strong><br />A creative artist proficient in painting imaginative works on canvas using watercolors and acrylic paints.</p>
                        <p className='pb-3'><strong>Exhibitions</strong><br />Participant in art exhibitions conducted by the ArtLimerick community of artists.</p>
                        <p className='pb-3'><strong>Client Support</strong><br />Providing clients with end-to-end website development services and commissioned artworks.</p>
                        <h2 className='pt-4'><span>Additional Information</span></h2>
                        <p className='pb-3'><strong>Visa Status</strong><br />Stamp 4 Visa holder.</p>
                        <p><a href='/about'><button className='btn btn-success'>Read more <i className="bi bi-chevron-right"></i></button></a></p>
                    </div>
            </div> 
        </section>
    );
}

export default HomeIntro;